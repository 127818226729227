import React, {useState, useEffect} from "react";
import {Grid, Tab} from "semantic-ui-react";
import AdmiralMetadataTab from "./AdmiralMetadataTab";
import AdmiralTemplateTab from "./AdmiralTemplateTab";
import SecureTabPane from "../../SecureTabPane";
import AdmiralDataProvider from "../../../Services/AdmiralDataProvider";

AdmiralDataProvider.init({baseURL: process.env.ADMIRAL_URL, readOnlyURL: process.env.ADMIRAL_READONLY_URL});

export default function AdmiralAdminMasterDetailView(props) {
    const [metadata, setMetadata] = useState({});

    useEffect(function getMetadataOnLoad() {
        refreshMetadata();
    }, []);

    const refreshMetadata = () => {
        AdmiralDataProvider.getMetadata().then(response => {
            if (response && response.hasOwnProperty("message") && response.message.toLowerCase() === "success") {
                setMetadata(response.metadata);
            } else {
                setMetadata({});
            }
        });
    };

    return (
        <Grid>
            <Grid.Column width={16} className="masterContainer">
                <Tab
                    panes={[
                        {
                            menuItem: {
                                key: "admiral-templates",
                                content: <label>Admiral Templates</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    permissionsRequired={props.permissionsRequired}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    component={<AdmiralTemplateTab toast={props.toast} metadata={metadata} AdmiralDataProvider={AdmiralDataProvider} checkIfAuthorized={props.checkIfAuthorized} user={props.user} />}
                                    module={props.module}
                                    service={props.service}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        },
                        {
                            menuItem: {
                                key: "admiral-metadata",
                                content: <label>Admiral Metadata</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    permissionsRequired={["urn:admiral:internal"]}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    component={<AdmiralMetadataTab toast={props.toast} metadata={metadata} refreshMetadata={refreshMetadata} AdmiralDataProvider={AdmiralDataProvider} />}
                                    module={props.module}
                                    service={props.service}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        },

                    ]}
                />
            </Grid.Column>
        </Grid>
    );
};
