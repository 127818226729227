import React from "react";
import {Modal, Table, Icon, Button} from "semantic-ui-react";

export default function DeleteTemplateModal(props) {
    return (
        <Modal
            closeIcon
            open={props.open}
            onOpen={() => props.setOpen(true)}
            onClose={() => props.setOpen(false)}
            trigger={<Button color="red" fluid icon onClick={props.deleteClicked} disabled={props.disabled}><Icon name="trash"/>&nbsp;Delete Template</Button>}
            size="small"
        >
            <Modal.Header>Confirm Template Deletion</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    Are you sure you want to delete the following template?

                    <Table celled fixed striped>
                        <Table.Body>
                            <Table.Row>
                                <Table.HeaderCell width={5}>Brand</Table.HeaderCell>
                                <Table.Cell width={11}>{props.brand}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Profile Type</Table.HeaderCell>
                                <Table.Cell>{props.profileType}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Source</Table.HeaderCell>
                                <Table.Cell>{props.source}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Destination</Table.HeaderCell>
                                <Table.Cell>{props.destination}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Tag</Table.HeaderCell>
                                <Table.Cell>{props.tag ? props.tag : <Icon name="ban" color="red" />}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Publish to Destination</Table.HeaderCell>
                                <Table.Cell><Icon name={props.publish ? "check" : "x"} color={props.publish ? "green": "red"}/></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => props.deleteTemplate()}>Delete Template</Button>
                <Button secondary onClick={() => props.setOpen(false)}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    );
};
