import React, {useState, useEffect} from "react";
import {Button, Checkbox, Container, Grid, Icon, Menu, Message, Modal, Popup, Table} from "semantic-ui-react";
import ReactFileReader from "react-file-reader";

export default function ProfileImportModal(props) {
    const [profilesToImport, setProfilesToImport] = useState({});
    const [allProfiles, setAllProfiles] = useState({});
    const [profileIds, setProfileIds] = useState([]);
    const [selectedId, setSelectedId] = useState("");
    const [selectedProfile, setSelectedProfile] = useState({});
    const [selectedProfileStatus, setSelectedProfileStatus] = useState("");
    const [selectedProfileStatusDetails, setSelectedProfileStatusDetails] = useState("");
    const [importError, setImportError] = useState("");
    const [saving, setSaving] = useState(false);

    useEffect(function updateAllProfileIds() {
        if (Array.isArray(props.profiles) && props.profiles.length > 0) {
            const updatedAllProfiles = [];
            for (const profile of props.profiles) {
                updatedAllProfiles[profile._id] = profile;
            }
            setAllProfiles(updatedAllProfiles);
        } else {
            setAllProfiles([]);
        }
    }, [JSON.stringify(props.profiles)]);

    useEffect(function onClose() {
        if (!props.open) {
            setProfilesToImport({});
            setImportError("");
            setSelectedProfileStatus("");
            setSelectedId("");
            setSelectedProfile({});
            setSelectedProfileStatusDetails("");
            setSaving(false);
        }
    }, [props.open])

    const importProfiles = files => {
        setImportError("");
        setSelectedId("");
        setSelectedProfile({});
        const file = files.item(0);
        file.text().then(text => {
            const json = JSON.parse(text);
            if (!Array.isArray(json)) {
                setImportError("The file imported is not properly formatted, or was modified after export.");
                return;
            }

            const updatedProfiles = {};
            const updatedProfileIds = [];

            for (const profile of json) {
                const _id = profile._id;
                if (_id) {
                    updatedProfileIds.push(_id);
                    const profileExists = allProfiles.hasOwnProperty(_id);
                    updatedProfiles[_id] = {
                        import: !profileExists,
                        status: profileExists ? "warning" : "ready",
                        details: profileExists ? "Profile Already Exists" : "Ready to Import",
                        profile
                    };
                }
            }
            setProfileIds(updatedProfileIds);
            setProfilesToImport(updatedProfiles);
        }).catch(error => {
            console.error(error);
            setImportError("There was an error importing your file.");
        });
    };

    useEffect(function onProfileSelect() {
        if (selectedId && profilesToImport.hasOwnProperty(selectedId)) {
            setSelectedProfile(profilesToImport[selectedId].profile);
        } else {
            setSelectedProfile({});
        }
    }, [selectedId]);

    useEffect(function onProfileUpdate() {
        if (selectedId) {
            setSelectedProfileStatus(profilesToImport[selectedId].status);
            setSelectedProfileStatusDetails(profilesToImport[selectedId].details);
        } else {
            setSelectedProfileStatus("");
            setSelectedProfileStatusDetails("");
        }
    }, [JSON.stringify(selectedProfile), JSON.stringify(profilesToImport)]);

    const importAllClicked = async () => {
        for (const key of Object.keys(profilesToImport)) {
            if (profilesToImport[key].import === true) {
                await importOne(key);
            }
        }
        props.updateProfiles();
    };

    const importOneClicked = () => {
        importOne(selectedId).then(() => {
            props.updateProfiles();
        })
    };

    const importOne = async id => {
        if (profilesToImport.hasOwnProperty(id)) {
            let savingFunction = allProfiles.hasOwnProperty(id) ? props.AdConfigurationDataProvider.update : props.AdConfigurationDataProvider.newProfile;
            setSaving(true);
            savingFunction(profilesToImport[id].profile).then(response => {
                const updatedImportedProfiles = Object.assign({}, profilesToImport);
                updatedImportedProfiles[id].import = false;
                if (response.hasOwnProperty("error")) {
                    const error = response.error.response && response.error.response.data && response.error.response.data.error ? response.error.response.data.error : response.error.toString();
                    updatedImportedProfiles[id].status = "error";
                    updatedImportedProfiles[id].details = error;
                } else {
                    updatedImportedProfiles[id].status = "success";
                    updatedImportedProfiles[id].details = "Import Complete";
                }

                setProfilesToImport(updatedImportedProfiles);
            }).catch(error => {
                console.error("error thrown while importing: ", error);
                const updatedImportedProfiles = Object.assign({}, profilesToImport);
                updatedImportedProfiles[id].status = "error";
                updatedImportedProfiles[id].import = false;
                updatedImportedProfiles[id].details = error.toString();
            }).finally(() => {
                setSaving(false);
            });
        }
    };

    return (
        <Modal
            size="large"
            open={props.open}
            onClose={() => props.setOpen(false)}
            trigger={props.trigger}
        >
            <Modal.Header>
                Ad Profile Import
            </Modal.Header>
            <Modal.Content scrolling style={{height: "50vh"}}>
                <Modal.Description>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign="left">
                                <ReactFileReader handleFiles={importProfiles} fileTypes={[".json"]} multipleFiles={false} base64={false}>
                                    <Button secondary>Select File to Import</Button>
                                </ReactFileReader>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                                {
                                    Object.keys(profilesToImport).length > 0 ?
                                        <Button primary onClick={() => importAllClicked()}>Import Selected</Button> : ""
                                }
                            </Grid.Column>
                        </Grid.Row>
                        {
                            Object.keys(profilesToImport).length > 0 ?
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <Container fluid>
                                            <Menu vertical fluid secondary>
                                                {
                                                    profileIds.map(_id => {
                                                        const profile = profilesToImport[_id];
                                                        if (profile) {
                                                            const status = profile.status;
                                                            let icon =
                                                                status === "error" ? "x" :
                                                                status === "success"? "check" :
                                                                status === "invalid" ? "ban" :
                                                                status === "saving" ? "spinner" :
                                                                status === "warning" ? "exclamation" :
                                                                    "plus";
                                                            let statusColor =
                                                                status === "error" || status === "invalid" ? "red" :
                                                                status === "saving" ? "blue" :
                                                                status === "warning" ? "yellow" :
                                                                status === "success" ? "green" : "black";
                                                            return (
                                                                <Menu.Item
                                                                    key={`profile-import-${_id}`}
                                                                    active={_id === selectedId}
                                                                    onClick={() => setSelectedId(_id)}
                                                                >
                                                                    <Checkbox
                                                                        checked={profilesToImport[_id].import}
                                                                        onClick={
                                                                            (event, {checked}) => {
                                                                                const updatedImportedProfiles = Object.assign({}, profilesToImport);
                                                                                updatedImportedProfiles[_id] = Object.assign({}, profilesToImport[_id], {import: checked});
                                                                                setProfilesToImport(updatedImportedProfiles);
                                                                            }
                                                                        }
                                                                    />
                                                                    &nbsp;{_id}
                                                                    <Popup
                                                                        hoverable
                                                                        content={profilesToImport[_id].details}
                                                                        trigger={<Icon name={icon} size="large" color={statusColor} loading={saving && status === "saving"} />}
                                                                    />
                                                                </Menu.Item>
                                                            );
                                                        } else {
                                                            console.log(`profile with id ${_id} was not found or invalid`, profilesToImport[_id], profilesToImport);
                                                        }
                                                    })
                                                }
                                            </Menu>
                                        </Container>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <Container fluid>
                                            {
                                                selectedId.length === 0 ?
                                                    <Message icon color="yellow">
                                                        <Icon name="info" />
                                                        <Message.Content>
                                                            Select a profile to see its details and import status.
                                                        </Message.Content>
                                                    </Message> :
                                                    <Container>
                                                        <Table fixed celled striped>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Profile ID</Table.HeaderCell>
                                                                    <Table.Cell>{selectedId}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Status</Table.HeaderCell>
                                                                    <Table.Cell>{selectedProfileStatusDetails}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Brand</Table.HeaderCell>
                                                                    <Table.Cell>{selectedProfile.brand}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Platform</Table.HeaderCell>
                                                                    <Table.Cell>{selectedProfile.platform}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Content Type</Table.HeaderCell>
                                                                    <Table.Cell>{selectedProfile.contentType}</Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                        <Table fixed celled striped>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell colSpan="2">Ad Profile Properties</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>
                                                            <Table.Body>
                                                                {
                                                                    selectedProfile.hasOwnProperty("profile") ? Object.keys(selectedProfile.profile).map(key =>
                                                                        <Table.Row key={`${selectedId}-${key}`}>
                                                                            <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{key}</Table.HeaderCell>
                                                                            <Table.Cell>{selectedProfile.profile[key]}</Table.Cell>
                                                                        </Table.Row>
                                                                    ) : ""
                                                                }
                                                            </Table.Body>
                                                        </Table>
                                                    </Container>
                                            }
                                        </Container>

                                    </Grid.Column>
                                </Grid.Row> : ""
                        }
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)} secondary>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};
