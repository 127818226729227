import React, {useState, useEffect} from "react";
import {Button, Container, Grid, Icon} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import AdmiralTemplateForm from "./AdmiralTemplateForm";
import {Link} from "react-router-dom";

export default function AdmiralTemplateTab(props) {
    const [loading, setLoading] = useState(false);
    const [selectedKey, setSelectedKey] = useState("");
    const [newClicked, setNewClicked] = useState(false);
    const [templateData, setTemplateData] = useState([]);
    const [brand, setBrand] = useState("");
    const [profileType, setProfileType] = useState("");
    const [source, setSource] = useState("");
    const [destination, setDestination] = useState("");
    const [publish, setPublish] = useState(false);
    const [tag, setTag] = useState("");
    const [template, setTemplate] = useState("");
    const [triggerSelectedTemplateUpdate, setTriggerSelectedTemplateUpdate] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [userCanSave, setUserCanSave] = useState(false);

    useEffect(function initAuthDataProvider() {
        props.checkIfAuthorized(["urn:all:aspen-power"]).then(authorized => {
            setUserCanSave(authorized === true);
        });
    }, [JSON.stringify(props.user)]);

    useEffect(function getTemplatesOnLoad() {
        refreshTemplates();
    }, []);

    useEffect(function onSelectedKeyChange() {
        setTriggerSelectedTemplateUpdate(true);
    }, [selectedKey]);

    useEffect(function updateSelectedTemplate() {
        if (triggerSelectedTemplateUpdate) {
            setFromSelectedKey();
            setTriggerSelectedTemplateUpdate(false);
        }
    }, [triggerSelectedTemplateUpdate]);

    useEffect(function clearOnNewClicked() {
        if (newClicked === true) {
            setSelectedKey("");
            setBrand("");
            setProfileType("");
            setSource("");
            setDestination("");
            setPublish(false);
            setTemplate("");
            setUnsavedChanges(false);
        }
    }, [newClicked])

    useEffect(function diff() {
        new Promise((resolve, reject) => {
            if (newClicked) {
                resolve({
                    brand: "",
                    profile_type: "",
                    source: "",
                    destination: "",
                    template: "",
                    publish: false
                });
            }
            if (templateData) {
                let selectedTemplate = templateData.find(template => template._id === selectedKey);
                resolve(selectedTemplate);
            } else {
                reject();
            }
        }).then(selectedTemplate => {
            return brand !== selectedTemplate.brand || profileType !== selectedTemplate.profile_type ||
                source !== selectedTemplate.source || destination !== selectedTemplate.destination ||
                publish !== selectedTemplate.publish ||
                (tag !== selectedTemplate.tag && !!tag !== !!selectedTemplate.tag) || template !== atob(selectedTemplate.template);
        }).then(setUnsavedChanges).catch(() => {
            setUnsavedChanges(false);
        }).finally(() => {
            console.log(unsavedChanges);
        });
    }, [selectedKey, brand, profileType, source, destination, publish, tag, JSON.stringify(template), JSON.stringify(templateData)]);

    const setFromSelectedKey = () => {
        setUnsavedChanges(false);
        if (!selectedKey) {
            setBrand("");
            setProfileType("");
            setSource("");
            setDestination("");
            setPublish(false);
            setTag("");
            setTemplate("");
        } else {
            setNewClicked(false);
            const selectedTemplate = templateData.find(template => template._id === selectedKey);
            console.log(selectedTemplate);
            setBrand(selectedTemplate.brand);
            setProfileType(selectedTemplate.profile_type);
            setSource(selectedTemplate.source);
            setDestination(selectedTemplate.destination);
            setPublish(selectedTemplate.publish);
            setTag(selectedTemplate.tag || "");
            setTemplate(atob(selectedTemplate.template));
        }
    };

    const refreshTemplates = () => {
        new Promise(resolve => {
            setLoading(true);
            resolve();
        }).then(async () => {
            return await props.AdmiralDataProvider.getTemplates();
        }).then(async response => {
            if (response.error) {
                throw response.error;
            }
            console.log("(AdmiralTemplateTab.refreshTemplates) got templates: ", response);
            return response.templates;
        }).then(templates => {
            setTemplateData(templates);
        }).then(() => {
            setTriggerSelectedTemplateUpdate(true);
        }).catch(error => {
            console.log("(AdmiralTemplateTab.refreshTemplates) error: ", error);
            setTemplateData([]);
        }).finally(() => {
            setLoading(false);
        });
    };

    const saveTemplate = () => {
        if (brand && profileType && source && destination && template) {
            props.AdmiralDataProvider.saveTemplate(brand, profileType, source, destination, publish, tag, btoa(template)).then(response => {
                console.log("(AdmiralTemplateTab.saveTemplate) response: ", response);
                props.toast("success", "update complete");
                return response;
            }).then(() => {
                setNewClicked(false);
                refreshTemplates();
            }).catch(error => {
                console.error(error);
                props.toast("error", error.toString());
            });
        }
    };

    const deleteClicked = () => {
        if (brand && profileType && source && destination) {
            props.AdmiralDataProvider.deleteTemplate(brand, profileType, source, destination, tag).then(response => {
                console.log("AdmiralTemplateTab.deleteClicked) response: ", response);
                props.toast("success", "Template deletion completed");
                setSelectedKey("");
            }).then(() => {
                refreshTemplates();
            }).catch(error => {
                console.error(error);
                props.toast("error", error.toString());
            });
        }
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Grid.Row>
                        <Container fluid textAlign="right">
                            <Button.Group>
                                <Button as={Link} to={{pathname: "https://docsprod.turner.com/pages/viewpage.action?pageId=210046323"}} target="_blank" secondary icon>&nbsp;&nbsp;<Icon name="help"/>&nbsp;&nbsp;Admiral Help&nbsp;&nbsp;</Button>
                                <Button.Or text="" />
                                <Button as={Link} to={{pathname: "https://docsprod.turner.com/display/MPDTAS/Editing+Admiral+Templates"}} secondary target="_blank" icon>&nbsp;&nbsp;<Icon name="help"/>&nbsp;&nbsp;Template Help&nbsp;&nbsp;</Button>
                                <Button.Or text="" />
                                <Button onClick={refreshTemplates} icon>&nbsp;&nbsp;<Icon name="refresh"/>&nbsp;&nbsp;Refresh Templates&nbsp;&nbsp;</Button>
                                <Button.Or text="" />
                                <Button onClick={() => setNewClicked(true)} secondary disabled={!userCanSave} icon>&nbsp;&nbsp;<Icon name="plus"/>&nbsp;&nbsp;Create New Template&nbsp;&nbsp;</Button>
                            </Button.Group>
                        </Container>
                    </Grid.Row>
                    <br />
                    <Grid.Row>
                        <ReactTable
                            defaultPageSize={100}
                            columns={[
                                {accessor: "_id", show: false},
                                {Header: "Brand",
                                    accessor: "brand",
                                    Cell: row => props.metadata && props.metadata.hasOwnProperty("ingest_brands") && props.metadata.ingest_brands.hasOwnProperty(row.value) ?
                                        props.metadata.ingest_brands[row.value] : row.value,
                                    filterMethod: ((filter, rows) => {
                                        const cellValue = props.metadata && props.metadata.hasOwnProperty("ingest_brands") && props.metadata.ingest_brands.hasOwnProperty(rows[filter.id]) ?
                                            props.metadata.ingest_brands[rows[filter.id]] : rows[filter.id];
                                        return cellValue.toString().toLowerCase().includes(filter.value.toLowerCase());
                                    })
                                },
                                {
                                    Header: <span>Profile<br/>Type</span>,
                                    accessor: "profile_type",
                                    Cell: row => {
                                        const cellValue = props.metadata && props.metadata.hasOwnProperty("ingest_profiles") && props.metadata.ingest_profiles.hasOwnProperty(row.value) ?
                                            props.metadata.ingest_profiles[row.value] : row.value;
                                        return <Container>{cellValue}</Container>
                                    },
                                    filterMethod: (filter, rows) => {
                                        const cellValue = props.metadata && props.metadata.hasOwnProperty("ingest_profiles") && props.metadata.ingest_profiles.hasOwnProperty(rows[filter.id]) ?
                                            props.metadata.ingest_profiles[rows[filter.id]] : rows[filter.id];
                                        return cellValue.toString().toLowerCase().includes(filter.value.toLowerCase());
                                    }
                                },
                                {
                                    Header: "Source",
                                    accessor: "source",
                                    Cell: row => props.metadata && props.metadata.hasOwnProperty("ingest_sources") && props.metadata.ingest_sources.hasOwnProperty(row.value) ?
                                        props.metadata.ingest_sources[row.value] : row.value,
                                    filterMethod: (filter, rows) => {
                                        const cellValue = props.metadata && props.metadata.hasOwnProperty("ingest_sources") && props.metadata.ingest_sources.hasOwnProperty(rows[filter.id]) ?
                                            props.metadata.ingest_sources[rows[filter.id]] : rows[filter.id];
                                        return cellValue.toString().toLowerCase().includes(filter.value.toLowerCase());
                                    }
                                },
                                {
                                    Header: "Destination",
                                    accessor: "destination",
                                    Cell: row => props.metadata && props.metadata.hasOwnProperty("ingest_destinations") && props.metadata.ingest_destinations.hasOwnProperty(row.value) ?
                                        props.metadata.ingest_destinations[row.value] : row.value,
                                    filterMethod: (filter, rows) => {
                                        const cellValue = props.metadata && props.metadata.hasOwnProperty("ingest_destinations") && props.metadata.ingest_destinations.hasOwnProperty(rows[filter.id]) ?
                                            props.metadata.ingest_destinations[rows[filter.id]] : rows[filter.id];
                                        return cellValue.toString().toLowerCase().includes(filter.value.toLowerCase());
                                    }
                                },
                                {Header: "Tag", accessor: "tag"},
                                {
                                    Header: <span>Publish</span>,
                                    accessor: "publish",
                                    Cell: row => <Container textAlign="center"><Icon name={row.value ? "check" : "x"} color={row.value ? "green" : "red"}/></Container>,
                                    width: 77
                                },
                                {accessor: "template", show: false}
                            ]}
                            defaultSorted={[
                                {
                                    id: "brand",
                                    desc: false
                                },
                                {
                                    id: "profile_type",
                                    desc: false
                                },
                                {
                                    id: "source",
                                    desc: false
                                },
                                {
                                    id: "destination",
                                    desc: false
                                },
                                {
                                    id: "tag",
                                    desc: false
                                }
                            ]}

                            filterable
                            loading={loading}
                            data={templateData}
                            className="-striped -highlight"
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick(event, handleOriginal) {
                                        setSelectedKey(rowInfo.original._id);
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.original && selectedKey === rowInfo.original._id ? 'rgba(65, 83, 175, .5)' : '',
                                    }
                                }
                            }}
                            style={{height: "77vh"}}
                        />
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={8}>
                    <AdmiralTemplateForm
                        selectedKey={selectedKey}
                        brand={brand}
                        setBrand={setBrand}
                        profileType={profileType}
                        setProfileType={setProfileType}
                        source={source}
                        setSource={setSource}
                        destination={destination}
                        setDestination={setDestination}
                        tag={tag}
                        setTag={setTag}
                        template={template}
                        setTemplate={setTemplate}
                        saveTemplate={saveTemplate}
                        publish={publish}
                        setPublish={setPublish}
                        metadata={props.metadata}
                        AdmiralDataProvider={props.AdmiralDataProvider}
                        toast={props.toast}
                        unsavedChanges={unsavedChanges}
                        userCanSave={userCanSave}
                        deleteClicked={deleteClicked}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
