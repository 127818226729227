import React, {useEffect, useState} from "react";
import Jinja2Editor from "./Jinja2Editor";
import {Form, Container, Select, Button, Checkbox, Popup, Message, Input, Icon} from "semantic-ui-react";
import AdmiralTemplateTestingModal from "./AdmiralTemplateTestingModal";
import DeleteTemplateModal from "./DeleteTemplateModal";

export default function AdmiralTemplateForm(props) {
    const [brandOptions, setBrandOptions] = useState([]);
    const [sourceOptions, setSourceOptions] = useState([]);
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [profileTypeOptions, setProfileTypeOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(function getBrandsOnLoad() {
        console.log(props);
        if (props.metadata && props.metadata.hasOwnProperty("brand_mapping")) {
            const brands = Object.keys(props.metadata.brand_mapping);
            setBrandOptions(brands.map(brand => {
                const brandText = props.metadata && props.metadata.hasOwnProperty("ingest_brands") && props.metadata.ingest_brands.hasOwnProperty(brand) ?
                    props.metadata.ingest_brands[brand] : brand;
                return {key: brand, text: brandText, value: brand};
            }));
        } else {
            setBrandOptions([]);
        }
    }, [props.metadata]);

    useEffect(function getSourceOptionsOnLoad() {
        if (props.metadata && props.metadata.hasOwnProperty("source_profile_mapping")) {
            const sources = Object.keys(props.metadata.source_profile_mapping);

            setSourceOptions(sources.map(source => {
                const sourceText = props.metadata && props.metadata.hasOwnProperty("ingest_sources") && props.metadata.ingest_sources.hasOwnProperty(source) ?
                    props.metadata.ingest_sources[source] : source;
                return {key: source, text: sourceText, value: source};
            }));
        } else {
            setSourceOptions([]);
        }
    }, [props.metadata]);

    useEffect(function getProfileTypeOptionsOnSourceChange() {
        if (props.metadata && props.metadata.hasOwnProperty("source_profile_mapping") && props.metadata.source_profile_mapping.hasOwnProperty(props.source)) {
            const profileTypes = props.metadata.source_profile_mapping[props.source];
            setProfileTypeOptions(profileTypes.map(profileType => {
                const profileTypeText = props.metadata.hasOwnProperty("ingest_profiles") && props.metadata.ingest_profiles.hasOwnProperty(profileType) ?
                    props.metadata.ingest_profiles[profileType] : profileType;
                return {key: profileType, text: profileTypeText, value: profileType};
            }));

            if (Array.isArray(profileTypes) && profileTypes.length > 0) {
                props.setProfileType(profileTypes[0]);
                for (const profileType of profileTypes) {
                    if (profileType === props.profileType) {
                        props.setProfileType(profileType);
                        break;
                    }
                }
            }
        } else {
            setProfileTypeOptions([]);
            props.setProfileType("");
        }
    }, [props.source]);

    useEffect(function initDestinationOptionsOnLoad() {
        if (props.metadata && props.metadata.hasOwnProperty("ingest_destinations")) {
            setDestinationOptions(Object.keys(props.metadata.ingest_destinations).map(destination => {
                const destinationText = props.metadata.ingest_destinations[destination];
                return {key: destination, text: destinationText, value: destination};
            }))
        } else {
            setDestinationOptions([]);
        }
    }, [props.metadata]);

    const deleteClicked = () => {
        setIsDeleteModalOpen(false);
        props.deleteClicked();
    }

    return (
        <Container style={{paddingTop: "3rem !important"}} className="detailsContainer" fluid>
            <Form>
                <Form.Group widths="equal">
                    <Popup disabled={!props.userCanSave} content={<Message compact color="green">The selected template has unsaved changes.</Message>} open={props.unsavedChanges && !isModalOpen} trigger={<Button onClick={props.saveTemplate} fluid disabled={!props.userCanSave || !props.unsavedChanges} icon><Icon name="save"/>&nbsp;&nbsp;Save Template</Button>} />
                    <AdmiralTemplateTestingModal
                        template={props.template}
                        setTemplate={props.setTemplate}
                        AdmiralDataProvider={props.AdmiralDataProvider}
                        brand={props.brand}
                        tag={props.tag}
                        profileType={props.profileType}
                        metadata={props.metadata}
                        source={props.source}
                        destination={props.destination}
                        publish={props.publish}
                        toast={props.toast}
                        brandOptions={brandOptions}
                        profileTypeOptions={profileTypeOptions}
                        sourceOptions={sourceOptions}
                        setBrand={props.setBrand}
                        setProfileType={props.setProfileType}
                        setSource={props.setSource}
                        setDestination={props.setDestination}
                        open={isModalOpen}
                        setOpen={setIsModalOpen}
                    />
                    <DeleteTemplateModal
                        open={isDeleteModalOpen}
                        setOpen={setIsDeleteModalOpen}
                        brand={props.brand}
                        tag={props.tag}
                        profileType={props.profileType}
                        source={props.source}
                        destination={props.destination}
                        publish={props.publish}
                        deleteTemplate={deleteClicked}
                        disabled={!props.userCanSave || props.selectedKey === ""}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Select}
                        label="Brand"
                        value={props.brand}
                        options={brandOptions}
                        onChange={(event, {value}) => props.setBrand(value)}
                    />
                    <Form.Field
                        control={Select}
                        label="Profile Type"
                        value={props.profileType}
                        options={profileTypeOptions}
                        onChange={(event, {value}) => props.setProfileType(value)}
                    />
                    <Form.Field
                        control={Input}
                        label="Tag"
                        value={props.tag}
                        onChange={(event, {value}) => props.setTag(value)}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Select}
                        label="Source"
                        value={props.source}
                        options={sourceOptions}
                        onChange={(event, {value}) => props.setSource(value)}
                    />
                    <Form.Field
                        control={Select}
                        label="Destination"
                        value={props.destination}
                        options={destinationOptions}
                        onChange={(event, {value}) => props.setDestination(value)}
                    />
                    <Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                        </Form.Field>
                        <Form.Field>
                            <Checkbox label="Publish to Destination" checked={props.publish} onClick={(event, {checked}) => props.setPublish(checked)} />
                        </Form.Field>
                    </Form.Field>
                </Form.Group>
            </Form>
            <Container style={{overflowX: "auto", overflowY: "auto", maxHeight: "55vh"}} fluid>
                <Jinja2Editor code={props.template} setCode={props.setTemplate} minHeight="55vh" />
            </Container>
        </Container>
    );
};
