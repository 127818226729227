import React, {Component} from "react";
import {Button, Modal, Grid, Form, Container, Table, Icon} from "semantic-ui-react";
import JSONEditor from "jsoneditor";
import Jinja2Editor from "./Jinja2Editor";
import "jsoneditor/dist/jsoneditor.css";


export default class AdmiralTemplateTestingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorText: {},
            editorTextIsDictionary: true,
            templateTestingResponse: ""
        };

        this.onTestClick = this.onTestClick.bind(this);
    }

    setEditorText = () => {
        // cartoon:hulu:odt:freewheel
        const key = `${this.props.brand}:${this.props.profileType}:${this.props.source}:${this.props.destination}`;
        if (this.props.metadata.hasOwnProperty("test_payload_defaults") && this.props.metadata.test_payload_defaults.hasOwnProperty(key)) {
            const encodedValue = this.props.metadata.test_payload_defaults[key];
            if (encodedValue) {
                try {
                    this.setState({editorText: JSON.parse(atob(encodedValue)), templateTestingResponse: "", editorTextIsDictionary: true});
                } catch(e) {
                    console.error(`(AdmiralTemplateTestingModal.componentDidUpdate) error when getting default test payload for key ${key}: `, error);
                    this.setState({editorText: {}, templateTestingResponse: "", editorTextIsDictionary: true});
                }
            } else {
                this.setState({editorText: {}, templateTestingResponse: "", editorTextIsDictionary: true});
            }
        } else {
            this.setState({editorText: {}, templateTestingResponse: "", editorTextIsDictionary: true});
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open !== prevProps.open && !this.props.open) {
            this.setState({templateTestingResponse: ""});
            this.setEditorText();
        }

        if (this.props.open !== prevProps.open && this.props.open) {
            const options = {
                mode: 'text',
                onChangeText: jsonString => {
                    if (jsonString !== this.state.editorText) {
                        try {
                            this.setState({editorText: JSON.parse(jsonString)});
                        } catch (error) {
                            console.error("AdmiralTemplateTestingModal.componentDidUpdate) error with json.parse on ", jsonString);
                            this.setState({editorText: jsonString});
                        }
                    }
                }
            };

            this.jsoneditor = new JSONEditor(this.container, options);
            this.jsoneditor.set(this.state.editorText);
            this.setState({templateTestingResponse: ""});
        }

        if ((this.props.metadata !== prevProps.metadata || this.props.profileType !== prevProps.profileType) && this.props.metadata && this.props.metadata.hasOwnProperty("test_payload_defaults")) {
            this.setEditorText();
        }

        if (this.state.editorText !== prevState.editorText) {
            this.setState({editorTextIsDictionary: typeof this.state.editorText === "object"});
        }
    }

    onTestClick = () => {
        const encodedTemplate = btoa(this.props.template);
        const encodedTestPayload = this.state.editorTextIsDictionary ? btoa(unescape(encodeURIComponent(JSON.stringify(this.state.editorText)))) : "";
        this.props.AdmiralDataProvider.testTemplate(this.props.brand, this.props.profileType, this.props.source, this.props.destination, this.props.publish, encodedTemplate, encodedTestPayload).then(response => {
            if (response.error) {
                this.setState({templateTestingResponse: response.error.message});
            } else if (response.message && response.message.toString().toLowerCase() === "success") {
                    this.setState({templateTestingResponse: atob(response.result)});
            } else {
                this.setState({templateTestingResponse: response});
            }
        }).catch(error => {
            console.error(error);
            this.setState({templateTestingResponse: error.message});
        });
    };

    render() {
        return (
            <Modal
                onClose={() => this.props.setOpen(false)}
                onOpen={() => this.props.setOpen(true)}
                open={this.props.open}
                size="fullscreen"
                trigger={<Button fluid secondary icon><Icon name="file code"/>&nbsp;&nbsp;Open Template Tester</Button>}
                style={{position: "relative"}}
            >
                <Modal.Header>Template Testing</Modal.Header>
                <Modal.Content style={{height: "75vh"}}>
                    <Modal.Description>
                        <Grid columns="equal">
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Table compact>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Brand</Table.HeaderCell>
                                                <Table.HeaderCell>Source</Table.HeaderCell>
                                                <Table.HeaderCell>Profile Type</Table.HeaderCell>
                                                <Table.HeaderCell>Destination</Table.HeaderCell>
                                                <Table.HeaderCell>Tag</Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{this.props.brand}</Table.Cell>
                                                <Table.Cell>{this.props.source}</Table.Cell>
                                                <Table.Cell>{this.props.profileType}</Table.Cell>
                                                <Table.Cell>{this.props.destination}</Table.Cell>
                                                <Table.Cell>{this.props.tag}</Table.Cell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form>
                                        <Form.Field>
                                            <label>Test Payload (Optional)</label>
                                        </Form.Field>
                                    </Form>
                                    {this.props.open ? <div style={{height: "58vh"}} ref={elem => this.container = elem} /> : "" }
                                </Grid.Column>
                                <Grid.Column>
                                    <Form>
                                        <Form.Field>
                                            <label>Template</label>
                                        </Form.Field>
                                    </Form>
                                    <Container style={{overflowX: "auto", overflowY: "auto", maxHeight: "58vh"}} fluid>
                                        <Jinja2Editor
                                            code={this.props.template}
                                            setCode={this.props.setTemplate}
                                            minHeight="58vh"
                                        />
                                    </Container>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form>
                                        <Form.Field>
                                            <label>Result</label>
                                        </Form.Field>
                                    </Form>
                                    <Container style={{overflowX: "auto", overflowY: "auto", maxHeight: "58vh"}} fluid>
                                        <Jinja2Editor
                                            code={this.state.templateTestingResponse}
                                            onChange={(event, {value}) => this.setState({templateTestingResponse: value})}
                                            minHeight="58vh"
                                        />
                                    </Container>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="black" onClick={this.onTestClick} disabled={!this.state.editorTextIsDictionary && this.state.editorText !== ""}>Test</Button>
                    <Button onClick={() => this.props.setOpen(false)}
                >Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
};
